body {
  margin: 0;
  padding: 0;
  font-family: "Figtree", sans-serif;
  background-color: #0a0a0a;
  color: #fff;
  overflow-x: hidden;
}
a {
  color: #fff;
  text-decoration: none;
}
a:hover {
  color: #fff;
  text-decoration: underline;
}
.h2tag {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 15px;
}
.head {
  max-width: 72%;
  text-align: center;
  margin: 0 auto 15px;
}
.butn {
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(
      90deg,
      #10beda 0%,
      #ffe25e 50%,
      #ffe25e 100%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #070b1f inset, 0 0 10px 1px rgb(15 211 255 / 50%);
  color: #fff;
  padding: 8px 25px;
  border-radius: 100px;
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
  z-index: 1;
  position: relative;
  overflow: hidden;
  transition: 0.2s all;
}
.butn:hover {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(
      90deg,
      #ffe25e 0%,
      #ffe25e 50%,
      #10beda 100%
    );
  opacity: 1;
  color: #fff;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #fee993;
}

.main_navbar {
  background-color: transparent !important;
  z-index: 10;
  transition: 0.2s all;
  /* padding: 15px 0; */
}
.main_navbar.fixed_navbar {
  position: fixed;
  width: 100%;
  top: 41px;  
  background-color: #050505 !important;
  box-shadow: 0 0 10px 5px rgb(4 4 4 / 50%);
  z-index: 999;
  padding: 5px 0;
}
.main_navbar.fixed_navbar.main_navbar_inner {
  top: 0;
}
.main_navbar .navbar-toggler .navbar-toggler-icon {
  display: none;
}
.main_navbar .navbar-toggler {
  box-shadow: none !important;
}
.main_navbar .navbar-toggler i {
  color: #fff;
  font-size: 21px;
}
.main_navbar .addr_icon {
  height: 21px;
}
.main_navbar .navbar-collapse {
  justify-content: flex-end;
}
.logout_btn {
  background: transparent;
  border: 2px solid transparent;
  color: #fff;
  font-size: 20px;
  margin-right: 15px;
  transition: 0.2s all;
  padding: 5px 20px;
  border-radius: 30px;
}
.logout_btn:hover {
  background: #021039;
  border: 2px solid #0b1c47;
}
.logout_btn img {
  margin-right: 10px;
}
.navbar_right a+a {
  margin-left: 20px !important
}
.page_header {
  position: relative;
  padding: 120px 0 50px;
  background: url("../src/assets/images/banner_bg.png") no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 850px;
  margin-top: -200px;
  display: flex;
  align-items: center;
}
.page_header h1 {
  font-size: 42px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 10px;
}
.page_header h5 {
  font-size: 16px;
  line-height: 28px;
  font-weight: 300;
  color: #fff;
}
.page_header h5 span {
  color: #24d7ff;
  font-weight: 500;
}
.page_header button.primary_btn {
  margin: 30px 0 0;
}
.page_header button.primary_btn a {
  color: #0b162e;
  text-decoration: none;
}
.page_header button.primary_btn:hover a {
  color: #ffa719;
}
.orange_txt {
  color: #ffa719 !important;
}
section {
  padding: 80px 0;
}
.main_title_small {
  font-size: 24px;
  font-weight: 400;
  color: #fff;
}
.main_title {
  font-size: 48px;
  font-weight: 700;
  color: #feba31;
  margin-bottom: 30px;
}
.inner_para {
  font-size: 16px;
  color: #fff;
}
.how_works_section {
  background: #0a0a0a;
  position: relative;
  z-index: 1;
  box-shadow: inset 1px 2px 250px 0 rgb(21 192 218 / 50%);
}
/* .how_works_section::before {
  content: "";
  background: url("./assets/images/how_work_shadow.png");
  width: 100%;
  height: 112px;
  position: absolute;
  top: 0;
}
.how_works_section::after {
  content: "";
  background: url("./assets/images/how_work_shadow.png");
  width: 100%;
  height: 112px;
  position: absolute;
  bottom: 0px;
  z-index: -1;
  transform: rotate(180deg);
} */
.how_works_section h3 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
}
.how_works_section p {
  color: #fff;
  font-weight: 200;
}
.how_works_section button.primary_btn {
  margin: 30px 0 0 !important;
}
.how_works_section .row {
  justify-content: space-between;
}
.how_works_section .box {
  margin: 40px 0;
  margin-top: 80px;
}
.how_works_section .box.rightbox {
  text-align: right;
}
.how_works_section .butn {
  padding: 20px;
}
.primary_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 30px;
  box-sizing: border-box;
  color: #fff;
  background: #111f3d;
  background-clip: padding-box;
  border: 2px solid #223359;
  border-radius: 20px;
  transition: 0.2s all;
  overflow: hidden;
}
.features_section {
  position: relative;
}
.features_section::before {
  content: '';
  background: url("./assets/images/feature_bg.png") no-repeat;
  background-size: cover;
  width: 100%;  
  height: 719px;
  position: absolute;
}
.features_sectionbg {
  position: relative;
  margin-top: 150px;
}
.features_sectionbg .row [class*="col-"]:nth-child(2) {
  margin-top: 70px;
}
.features_sectionbg .row [class*="col-"]:first-child .primary_box.features_box {
  margin: 0 0 0 auto !important;
}
.features_sectionbg .row [class*="col-"]:last-child .primary_box.features_box {
  margin: 0 auto 0 0 !important;
}
.features_sectionbg .row [class*="col-"]:first-child .primary_box.features_box .imgbox {
  left: -45px;
}
.features_sectionbg .row [class*="col-"]:last-child .primary_box.features_box .imgbox {
  right: -45px;
}
.features_section .animated {
  height: 100%;
}
.features_box {
  cursor: default;
  text-align: center;
  align-items: center;
  padding: 80px 40px 35px;
  border-radius: 10px !important;
  z-index: 1;
}
.primary_box.features_box {
  position: relative;
  overflow: visible;
  background: #0a0a0a;
  border: 1px solid #f6dd7f;
  max-width: 80%;
  margin: 0 auto;
  transition: 0.2s all;
}
.primary_box.features_box:hover {
  box-shadow: inset 0 0 50px 0 rgba(239, 212, 93, 0.2);
}
.primary_box.features_box .imgbox {
  position: absolute;
  top: -100px;
}
.features_box h4 {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 20px;
}
.primary_box p {
  margin-bottom: 0;
}
.features_section [class*="col-"] {
  margin-bottom: 30px;
}
.register_section {
  background-color: #081125;
}
.how_register_div {
  width: 100%;
  padding: 40px;
  position: relative;
  margin-bottom: 60px;
  border: 2px solid #1d2d4f;
  border-radius: 30px;
}
.how_register_div:nth-child(even)::before {
  right: 50px;
}
.how_register_div:last-child::before {
  display: none;
}
.how_register_panel {
  margin-top: 40px;
}
.how_register_div h2 {
  font-size: 34px;
  font-weight: 800;
  color: #feba31;
  margin: 0 0 20px;
}
.how_register_div_content h4 {
  font-size: 24px;
  font-weight: 800;
  color: #feba31;
  margin: 0 0 10px;
}
.how_register_div_content p,
.how_register_div_content ol li {
  font-size: 16px;
  color: #fff;
  margin-bottom: 0;
}
.how_register_div_content ol li {
  margin-bottom: 20px;
}
.cta_section_02 {
  background: #06051e url("../src/assets/images/cta_02_bg.png") center no-repeat;
  border: 2px solid #242424;
  padding: 50px;
  border-radius: 30px;
  min-height: 250px;
}
.plan_tab.nav-pills li button.nav-link {
  border: 2px solid #223359;
  background-color: #111f3d;
  color: #7a7e89;
  margin: 10px;
  font-size: 18px;
  font-weight: 400;
}
.plan_tab.nav-pills li button.nav-link.active {
  color: #fff;
}
.plan_income_table {
  border-radius: 0;
  overflow: hidden;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
  cursor: default;
}
.plan_income_table th,
.plan_income_table td {
  vertical-align: middle;
  white-space: nowrap;
}
.plan_income_table td {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  height: 55px;
  border: solid 1px #232323 !important;
  border-top: none !important;
}
.plan_income_table th:first-child,
.plan_income_table td:first-child {
  padding-left: 30px;
  width: 150px;
  text-align: center;
  border-radius: 10px 0 0 10px;
  border-right: none !important;
}
.plan_income_table th:last-child,
.plan_income_table td:last-child {
  padding-right: 30px;
  border-radius: 0 10px 10px 0;
  border-left: none !important;
}
.plan_income_table th:nth-child(2),
.plan_income_table th:nth-child(3),
.plan_income_table td:nth-child(2),
.plan_income_table td:nth-child(3),
.plan_income_table th:nth-child(4),
.plan_income_table th:nth-child(5),
.plan_income_table td:nth-child(4),
.plan_income_table td:nth-child(5) {
  border-left: none !important;
  border-right: none !important;
}
.plan_income_table_div {
  border-radius: 10px;
  border-top: 1px solid #232323 !important;
  margin-top: 50px;
}
.plan_income_table th {
  color: #10beda;
  font-size: 18px;
  font-weight: 400;
  border-top: 1px solid #232323 !important; ;
  border: 1px solid #232323 !important;
  height: 55px;
}
.plan_income_table tr.footer_row td {
  border-radius: 10px !important;
  border: 1px solid #232323 !important;
  border-top: none !important;
}
.plan_income_table tr {  
  /* background: linear-gradient(to bottom, #431c8b -28%, #1f054d 115%) padding-box,
    linear-gradient(to top, #180142, #642bcc) border-box; */
  background: #0a0a0a;   
}
.plan_income_table tbody tr:hover {
  /* background: linear-gradient(0deg, rgba(24,36,92,1) 0%, rgba(10,15,40,1) 50%, rgba(24,36,92,1) 100%) !important;  */
  box-shadow: inset 0 0 50px 0 #232323;
}
.plan_income_table tbody tr:nth-child(even) {
  background-color: #0a0a0a;
}
.plan_income_table tbody tr:nth-child(odd) {
  background-color: #0a0a0a;
}
.plan_income_table td h4 {
  font-size: 18px;
  font-weight: 400;
  margin: 3px 0;
}
.plan_income_table.level_income th:first-child,
.plan_income_table.level_income td:first-child {
  width: 300px;
}
#faq_accodian {
  margin-top: 30px;
  margin-bottom: 80px;
}
#faq_accodian .accordion-header button {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: none;
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
  text-decoration: none;
  background: transparent;
  padding-left: 0;
}
#faq_accodian .accordion-header,
#faq_accodian .accordion-body {
  background-color: transparent;
}
#faq_accodian .accordion-item {
  position: relative;
  padding: 15px 10px;
  box-sizing: border-box;
  color: #fff;
  background: #152446;
  background-clip: padding-box;
  border-radius: 0;
  transition: 0.2s all;
  /* margin-bottom: 30px; */
  overflow: unset;
  background: transparent;
  border: none;
  border-bottom: 1px solid #232323 !important;
}
#faq_accodian .accordion-body {
  padding-top: 0;
  padding-left: 0;
}
#faq_accodian .accordion-body p {
  color: #a2b1cf;
  font-size: 14px;
  margin-bottom: 0;
  cursor: default;
}
#faq_accodian .accordion-collapse .collapse .show {
  position: relative;
}
.footer {
  background-color: #050919;
  padding: 30px 0 20px;
}
.footer_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_panel_left p {
  margin-bottom: 0;
  font-size: 16px;
}
.footer_panel_left a {
  font-size: 14px;
  color: #10beda;
  white-space: normal;
  word-break: break-all;
}
.footer_social_links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer_social_links li {
  margin: 0 5px;
}
.footer_social_links li a {
  background: #6f6d6d;
  color: #050505;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  text-decoration: none;
  transition: 0.2s all;
}
.footer_social_links li a:hover {
  background: #fff;
  color: #050919;
}
.footer_panel_right {
  text-align: center;
}
.primary_btn::before {
  background: #feba31;
  border-radius: 0;
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  right: -1px;
  top: 0;
  -webkit-transform: none;
  transform: none;
  transition: all 0.3s ease-in-out;
  will-change: transform;
  z-index: -1;
}
.primary_btn:hover,
.primary_btn:active,
.primary_btn.active {
  color: #feba31;
  background: transparent;
  border-color: #feba31;
}
.primary_btn:hover::before,
.primary_btn:active::before,
.primary_btn.active::before {
  border-radius: 30px;
  transform: translate3d(-101%, 0, 0);
}
.primary_btn {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 1.715;
  border: 2px solid #ffd47e !important;
  border-radius: 6px;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  transition: 250ms all ease-in-out;
  color: #0b162e;
  text-decoration: none !important;
  background: transparent;
  z-index: 1;
  box-shadow: 0 0 5px 2px rgb(254 186 49 / 70%) !important;
  margin: 30px auto 0;
}
.primary_btn .wallet_icon {
  filter: invert(1) hue-rotate(326deg) brightness(0.2) contrast(1);
  transition: 0.02s all ease-in-out;
  margin-right: 10px;
  height: 35px;
}
.primary_btn:hover .wallet_icon {
  filter: unset;
}
.navbar_right {
  display: flex;
  align-items: center;
}
.navbar_right .butn {
  margin: 0;
  font-size: 18px;
  padding: 10px 30px;
  text-decoration: none !important;
}
.navbar_right .butn a {
  text-decoration: none !important;
}
.navbar_right .butn.report {
  margin-right: 15px;
}
.d_d_none {
  display: none !important;
}
.inner_para a {
  word-break: break-all;
}

/* Inner Pages */
.inner_wrapper {
  min-height: 80vh;
  padding: 80px 0;
  position: relative;
}
.inner_wrapper::before {
  content: "";
  background-image: url("./assets/images/recent_act_bg.png");
  position: absolute;
  width: 100%;
  z-index: -1;
  background-size: 100% 100%;
  height: 100%;
  top: 0;
}
.inner_wrapper.bgimg::before {
  display: none;
}
.dashboard_panel {
  background: #0b162e;
  border-radius: 20px;
  padding: 30px;
}
.dashboard_panel.join_now_panel {
  min-height: 92vh;
  padding: 30px;
}
.title_flex {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 30px;
}
.inner_title {
  font-size: 36px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 0;
}
.wallet_div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wallet_div label {
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  white-space: nowrap;
  margin: 0px 5px 0 0;
}
.primary_inp {
  border: 1px solid #161616;
  background: #0e0e0e;
  color: #fff !important;
  font-size: 16px;
  border-radius: 10px;
  padding: 12px;
}
.primary_inp:focus {
  border: 1px solid #161616;
  background: #0e0e0e;
  box-shadow: none !important;
}
.primary_inp::placeholder {
  color: #909090;
}
.inp_grp {
  position: relative;
}
.inp_append {
  position: absolute;
  right: 12px;
  top: 10px;
  cursor: pointer;
}
.wallet_div input.primary_inp {
  width: 350px;
  background: transparent;
  border: none;
  box-shadow: none;
  color: #617398 !important;
  font-size: 20px;
  padding: 2px;
}
button.round_btn {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
img.copy_icon.wallet_icon {
  margin-right: 0;
}
.wallet_div .inp_grp {
  display: flex;
  align-items: center;
}
.last_login_details {
  font-size: 16px;
  font-weight: 200;
}
.dash_box_left {
  padding: 10px 0px;
  border-bottom: 1px solid #031542;
}
.dash_box_left:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.dash_box_left:first-child {
  padding-bottom: 20px;
}
.dash_box_left h2 {
  font-size: 24px;
  font-weight: 700;
}
.dash_box_left h5 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
.dash_box_left h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
}
.dash_box_left h3 span {
  color: #e8d7f8;
  font-size: 20px;
}
.dash_box_left p {
  color: #e8d7f8;
  font-size: 18px;
  margin-bottom: 0;
}
.dash_box_left h4 {
  color: #e8d7f8;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
.dashbox_middle_top {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0;
}
.dashbox_middle_top h2 {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}

.dashbox_middle_top .right_imgbox {
  border: 2px solid #e6ce77 !important;
  background: linear-gradient(90deg, rgb(238 216 151) 0%, rgb(234 193 85) 100%);
  padding: 10px;
  border-radius: 50% !important;
}
.right_imgbox img {
  filter: brightness(0.9) invert(1);
}
.dashbox_middle_top h4 {
  font-size: 18px;
  font-weight: 400;
  color: #617398;
}
.dashbox_middle_top > div {
  display: flex;
  align-items: center;
  cursor: default;
}
.dashbox_middle_bottom h2 {
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 15px;
  padding-bottom: 5px;
  margin-left: 5px;
}
.dashbox_middle_bottom h5 {
  font-size: 24px;
  font-weight: 500;
}
.dashboard_top_wrap [class*="col-"] {
  margin-bottom: 30px;
}
.dashbox_middle_bottom ul.footer_social_links li a {
  width: 28px;
  height: 28px;
  font-size: 14px;
}
.dashbox_middle_bottom ul.footer_social_links {
  justify-content: center;
  margin-top: 15px;
}
.dashbox_middle_bottom ul.footer_social_links li {
  margin: 0 10px 0px 0;
}

.dashboard_top_wrap > [class*="col-"]:last-child {
  margin-bottom: 0;
}
.search_div {
  width: 50%;
  margin: 50px auto 0;
}
.search_div button {
  background: #feba31;
  border: none;
  font-size: 18px;
  font-weight: 500;
  color: #0b162e;
  position: absolute;
  top: 1px;
  right: 0;
  border-radius: 0 10px 10px 0;
  padding: 6px 20px;
}
.navbar-brand {
  outline: 0;
}
.plan_wrapper {
  /* display: flex;
  flex-flow: wrap;
  justify-content: center; */
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.buy_item {
  background-color: #0b162e;
  padding: 20px 10px 20px;
  border-radius: 20px;
  position: relative;
  /* width: 200px; */
  margin: 50px 0 20px;
  cursor: default;
}
.buy_item h4 {
  font-size: 14px;
  font-weight: 500;
  color: #feba31;
}
.buy_item h5 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.buy_item .primary_btn {
  padding: 4px 12px;
  font-size: 16px;
  margin: 15px auto 0;
  width: auto !important;
  border: 2px solid transparent;
}
.buy_item .primary_btn:hover {
  color: #feba31;
  background-color: #000000 !important;
  box-shadow: 0 0 5px 2px rgb(254 186 49 / 70%);
}
.dashbox_middle_bottom .primary_btn {
  width: 100%;
  justify-content: center;
}
.Upgrade_btn {
  background: linear-gradient(304deg, #ff841d, #132858) padding-box,
    linear-gradient(204deg, #ffff03 19%, #415faa 46%, #6e1cbe) border-box;
  box-shadow: inset -8px 3px 20px 1px #8d3b3c;
}
.Upgrade_btn::before {
  background: #290668;
}
.buy_count {
  /* background: #feba31;
  border: 1px solid #ffd47e;
  box-shadow: 0 0 5px 2px rgb(254 186 49 / 70%);
  position: relative;
  border-radius: 50%;
  margin: -75px auto 15px;
  width: 130px;
  height: 130px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* .buy_count + div{
  display: flex;
  align-items: center;
  justify-content: space-between;
} */
.buy_count p {
  font-size: 14px;
  color: #0b162e;
  font-weight: 700;
}
.buy_count h2 {
  font-size: 36px;
  color: #0b162e;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  line-height: 40px;
}
.buy_count h3 {
  font-size: 24px;
  color: #0b162e;
  font-weight: 700;
  margin: 0;
}
.buy_data {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}
.buy_data_item {
  display: flex;
  align-items: center;
}
.buy_data_count p {
  font-size: 18px;
  color: #fff;
  line-height: 26px;
  margin-left: 5px;
}
.chance_panel h2 {
  font-size: 24px;
  text-transform: uppercase;
}
.buy_item.buy_item_disabled {
  opacity: 0.6;
  pointer-events: none;
  user-select: none;
}
/* .buy_item:hover .buy_count {
  animation-name: hvr-pulse;
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
} */
.primary_box.dash_box {
  padding: 20px 30px;
  background: linear-gradient(176deg, #0a0a0a, #0a0a0a) padding-box, linear-gradient(2deg, #0a0a0a, #0a0a0a) border-box;
  border: 1px solid #232323;
  /* box-shadow: inset 0px 4px 4px 1px rgb(15 211 255 / 50%); */
  border-radius: 20px;
}
.primary_box.dash_box.spacepad {
  padding: 10px;
}
.leftbox .primary_box.dash_box {
  /* box-shadow: inset 0px 4px 4px 1px rgb(15 211 255 / 50%); */
}
.rightbox .primary_box.dash_box {
  padding-top: 25px;
  background: linear-gradient(176deg, #0a0a0a, #0a0a0a) padding-box,
    linear-gradient(358deg, #0a0a0a 40%, #0a0a0a 52%) border-box;
}
img.brand_logo {
  width: 200px;
}
@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
/* Connect Wallet */
.primary_modal .modal-content {
  background: #010101;
}
.primary_modal .modal-header {
  border-bottom: 1px solid #161616;
  padding: 20px 24px 15px;
}
.connect_wallet_button {
  border: 2px solid #161616;
  background-color: transparent;
  color: #111111;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.2s all;
}
.connect_wallet_button:hover {
  border-color: #24d7ff;
}
.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}
.connect_wallet_button img {
  margin-right: 15px;
}
.connect_wallet_button span {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}
.btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  background: transparent url("../src/assets/images/close_icon.png") no-repeat;
  box-shadow: none !important;
  filter: invert(0) brightness(20.5);
}
.primary_modal a {
  text-decoration: none;
}
.node circle {
  fill: #feba31 !important;
  stroke: #feba31 !important;
  stroke-width: 1.5px !important;
  background: url("../src/assets/images/tree_icon.png");
  width: 13px;
  height: 21px;
}
.node text {
  font-size: 11px !important;
  background-color: #444 !important;
  fill: #fff !important;
}
.node {
  cursor: pointer;
}
path.link {
  stroke: #feba31 !important;
  stroke-width: 1.5px !important;
}
.dash_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0px;
  cursor: pointer;
  margin-right: 15px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
  color: #feba31;
  box-shadow: 0 0 0 3px #feba31;
  transition: color 0.3s;
}
.dash_icon:after {
  top: -2px;
  left: -2px;
  padding: 2px;
  z-index: -1;
  background: #feba31;
  transition: transform 0.2s, opacity 0.2s;
}
.dash_icon:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.dashbox_middle_top > div:hover .dash_icon::after {
  transform: scale(0.85);
}
.address_btn {
  display: block;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  width: 200px;
}

ul.walletdrop {
  background: #0e1538;
  border: 1px solid #213180;
}
ul.walletdrop li a {
  color: #eee;
  text-decoration: none;
}
ul.walletdrop li a:hover {
  background-color: #213180;
  color: #eee;
}
.join_now_panel .primary_box.dash_box {
  height: 100%;
}
.join_now_panel .primary_box.dash_box.emergency_box {
  align-items: center;
}
.join_now_panel .row [class*="col-"] {
  margin-bottom: 30px;
}
.timer_panel {
  display: flex;
  justify-content: center;
}
span.timer_label {
  display: block;
  color: #dfdfdf;
  font-size: 18px;
}
.timer_panel > span {
  margin: 0 8px;
  min-width: 60px;
}
span.timer_time {
  color: #ffffff;
  font-size: 36px;
  font-weight: 700;
}
span.timer_dots {
  margin: 0;
  line-height: 46px;
  color: #ffffff;
  font-size: 36px;
  min-width: auto;
}
.terms_con::before {
  display: none;
}
.terms_con .dashboard_panel {
  background: #050505;
  border: 1px solid #232323;
}
.platform_activity {
  position: relative;
}
.platform_activity::before {
  content: "";
  background: url("./assets/images/recent_act_bg.png") no-repeat center;
  position: absolute;
  width: 100%;
  z-index: -1;
  background-size: 100% 100%;
  height: 1338px;
  top: -200px;
}
.platform_activity p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 300;
}
.platform_activity p.violet {
  color: #ff4e00;
}
.platform_activity p.green span {
  color: #10beda;
}
.platform_activity .activity_box {
  background: #0a0a0a;
  border: 1px solid #232323;
  padding: 20px;
  border-radius: 20px;
  padding-top: 10px;
  padding-right: 10px;
  margin-top: 50px;
}
.platform_activity .activity_box_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #0a0a0a;
  background: #070b1f;
  padding: 20px 30px;
  border-radius: 10px;
  margin-bottom: 15px;
}
.platform_activity .activity_box_flx:hover {
  background: linear-gradient(0deg, #232323 0%, rgba(10,15,40,1) 50%, #232323 100%) !important;
}
.seemorebutn_wrap {
  text-align: center;
  margin-bottom: -42px;
}
.platform_activity .activity_scroll {
  padding-right: 12px;
}
.platform_activity .flxbox {
  display: flex;
  align-items: center;
}
.platform_activity .flxbox div {
  margin-right: 30px;
}
.platform_activity .flxbox .btn {
  background: linear-gradient(90deg, rgba(97,34,217,1) 0%, rgba(7,11,31,1) 100%);
  border: 1px solid #530cd5;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  border-radius: 30px;
  margin-right: 0;
  padding: 7px 25px;
}
.platform_activity .rightbtn p {
  display: flex;
  align-items: center;
  color: #fff;
}
.platform_activity .activity_box .seemorebutn {
  font-size: 18px;
  color: #fff;
  padding: 10px 30px;
  border: solid 2px transparent;
  background-image: linear-gradient( rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) ), linear-gradient( 90deg, rgba(15, 211, 255, 1) 0%, rgba(213, 73, 12, 1) 50%, rgba(83, 12, 213, 1) 100% );
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #070b1f inset, 0 0 10px 1px rgb(15 211 255 / 50%);
  border-radius: 100px;
}
.platform_activity .activity_box .seemorebutn:hover {
  background-image: linear-gradient( rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) ), linear-gradient( 90deg, rgba(83, 12, 213, 1) 0%, rgba(213, 73, 12, 1) 50%, rgba(15, 211, 255, 1) 100% );
  opacity: 1;
  color: #fff;
}
.above_info_box {
  background: rgb(242,230,190);
  background: linear-gradient(90deg, rgba(242,230,190,1) 0%, rgba(234,193,84,1) 57%, rgba(255,234,148,1) 100%);
  padding: 10px 0;
  z-index: 100;
  position: relative;
}
.above_info_box ::-webkit-scrollbar {
  width: 1px;
  height: 4px;
}
/* .above_info_box  ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.above_info_box  ::-webkit-scrollbar-thumb {
  background-color: #ff0000;
} */

.above_info_box .above_box_flx {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}
.above_info_box .above_box_flx div {
  display: flex;
  flex-wrap: nowrap;
  padding: 0 15px;
}
.above_info_box .above_box_flx div p {
  font-size: 14px;
  color: #0a0a0a;
  margin-right: 20px;
  margin-bottom: 0;
  white-space: nowrap;
}
.above_info_box .above_box_flx div span {
  color: #0a0a0a;
  font-size: 14px;
}
.above_info_box.above_fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
}
.copy_admin_flx {
  display: flex;
  align-items: center;
}
.copy_admin {
  box-shadow: none !important;
  border: 2px solid transparent !important;
  background: linear-gradient(195deg, #7139d8, #1f054d) padding-box,
    linear-gradient(360deg, #200451, #813cff) border-box;
  margin-left: 10px;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  text-align: center;
  width: 40px;
  height: 40px;
  font-size: 15px;
  padding: 20px;
}
.dash_user_search_box {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
.dash_user_search_box button.butn {
  margin: 30px auto 10px;
  padding: 8px 40px;
}
.ct-particle-animate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}
.ct-particle-animate div {
  position: absolute;
  top: 0;
  left: 0;
}
.ct-particle-animate #ct_particle_animate-a461fd71 {
  left: 7%;
  top: 28%;
}
.ct-particle-animate .shape-animate1 {
  animation: animationFramesTwo 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd72 {
  left: 25%;
  top: 10%;
}
.ct-particle-animate .shape-animate2 {
  animation: animationFramesOne 15s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd73 {
  left: 18%;
  top: 45%;
}
.ct-particle-animate #ct_particle_animate-a461fd83 {
  left: 18%;
  top: 15%;
}
.ct-particle-animate .shape-animate3 {
  animation: animationFramesOne 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd74 {
  left: 72%;
  top: 50%;
}
.ct-particle-animate #ct_particle_animate-a461fd84 {
  left: 70%;
  top: 25%;
}
.ct-particle-animate .shape-animate4 {
  animation: animationFramesFour 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd75 {
  left: 74%;
  top: 24%;
}
.ct-particle-animate .shape-animate5 {
  animation: animationFramesThree 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd77 {
  left: 20%;
  top: 70%;
}
.ct-particle-animate #ct_particle_animate-a461fd87 {
  left: 20%;
  top: 35%;
}
.ct-particle-animate .shape-left-right {
  animation: NewsletterAnimated2 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd76 {
  left: 90%;
  top: 60%;
}
.ct-particle-animate #ct_particle_animate-a461fd86 {
  left: 90%;
  top: 29%;
}
.ct-particle-animate .shape-right-left {
  animation: NewsletterAnimated1 12s infinite linear alternate;
}
@keyframes animationFramesOne {
	0% {
		transform: translate(0, 0)rotate(0)
	}
	20% {
		transform: translate(73px, -1px)rotate(36deg)
	}
	40% {
		transform: translate(111px, 72px)rotate(72deg)
	}
	60% {
		transform: translate(93px, 122px)rotate(108deg)
	}
	80% {
		transform: translate(-70px, 72px)rotate(124deg)
	}
	100% {
		transform: translate(0, 0)rotate(0)
	}
}

@-webkit-keyframes animationFramesOne {
	0% {
		-webkit-transform: translate(0, 0)rotate(0)
	}
	20% {
		-webkit-transform: translate(73px, -1px)rotate(36deg)
	}
	40% {
		-webkit-transform: translate(111px, 72px)rotate(72deg)
	}
	60% {
		-webkit-transform: translate(83px, 122px)rotate(108deg)
	}
	80% {
		-webkit-transform: translate(-40px, 72px)rotate(124deg)
	}
	100% {
		-webkit-transform: translate(0, 0)rotate(0)
	}
}
@keyframes animationFramesTwo {
	0% {
		transform: translate(0, 0)rotate(0)scale(1)
	}
	20% {
		transform: translate(73px, -1px)rotate(36deg)scale(.9)
	}
	40% {
		transform: translate(141px, 72px)rotate(72deg)scale(1)
	}
	60% {
		transform: translate(83px, 122px)rotate(108deg)scale(1.2)
	}
	80% {
		transform: translate(-40px, 72px)rotate(104deg)scale(1.1)
	}
	100% {
		transform: translate(0, 0)rotate(0)scale(1)
	}
}

@-webkit-keyframes animationFramesTwo {
	0% {
		-webkit-transform: translate(0, 0)rotate(0)scale(1)
	}
	20% {
		-webkit-transform: translate(73px, -1px)rotate(36deg)scale(.9)
	}
	40% {
		-webkit-transform: translate(141px, 72px)rotate(72deg)scale(1)
	}
	60% {
		-webkit-transform: translate(83px, 122px)rotate(108deg)scale(1.2)
	}
	80% {
		-webkit-transform: translate(-40px, 72px)rotate(104deg)scale(1.1)
	}
	100% {
		-webkit-transform: translate(0, 0)rotate(0)scale(1)
	}
}

@keyframes animationFramesThree {
	0% {
		transform: translate(165px, -179px)
	}
	100% {
		transform: translate(-346px, 200px)
	}
}

@-webkit-keyframes animationFramesThree {
	0% {
		-webkit-transform: translate(165px, -179px)
	}
	100% {
		-webkit-transform: translate(-346px, 200px)
	}
}

@keyframes animationFramesFour {
	0% {
		transform: translate(-300px, 151px)rotate(0)
	}
	100% {
		transform: translate(251px, -200px)rotate(180deg)
	}
}

@-webkit-keyframes animationFramesFour {
	0% {
		-webkit-transform: translate(-300px, 151px)rotate(0)
	}
	100% {
		-webkit-transform: translate(251px, -200px)rotate(180deg)
	}
}

@keyframes animationFramesFive {
	0% {
		transform: translate(61px, -99px)rotate(0)
	}
	21% {
		transform: translate(4px, -190px)rotate(38deg)
	}
	41% {
		transform: translate(-139px, -200px)rotate(74deg)
	}
	60% {
		transform: translate(-263px, -164px)rotate(108deg)
	}
	80% {
		transform: translate(-195px, -49px)rotate(144deg)
	}
	100% {
		transform: translate(-1px, 0)rotate(180deg)
	}
}

@-webkit-keyframes animationFramesFive {
	0% {
		-webkit-transform: translate(61px, -99px)rotate(0)
	}
	21% {
		-webkit-transform: translate(4px, -190px)rotate(38deg)
	}
	41% {
		-webkit-transform: translate(-139px, -200px)rotate(74deg)
	}
	60% {
		-webkit-transform: translate(-263px, -164px)rotate(108deg)
	}
	80% {
		-webkit-transform: translate(-195px, -49px)rotate(144deg)
	}
	100% {
		-webkit-transform: translate(-1px, 0)rotate(180deg)
	}
}

@keyframes animationFramesSix {
	0% {
		transform: translate(31px, -45px)rotate(0)
	}
	21% {
		transform: translate(4px, -80px)rotate(19deg)
	}
	41% {
		transform: translate(-70px, -100px)rotate(36deg)
	}
	60% {
		transform: translate(-133px, -84px)rotate(54deg)
	}
	80% {
		transform: translate(-98px, -24px)rotate(72deg)
	}
	100% {
		transform: translate(-1px, 0)rotate(180deg)
	}
}
@keyframes NewsletterAnimated1 {
	0% {
		transform: translateX(0)
	}
	25% {
		transform: translateX(-40px)
	}
	50% {
		transform: translateX(0)
	}
	75% {
		transform: translateX(40px)
	}
	100% {
		transform: translateX(0)
	}
}
@keyframes NewsletterAnimated2 {
	0% {
		transform: translateX(0)
	}
	25% {
		transform: translateX(40px)
	}
	50% {
		transform: translateX(0)
	}
	75% {
		transform: translateX(-40px)
	}
	100% {
		transform: translateX(0)
	}
}
.dashbox_middle_bottom_flex {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.dashbox_middle_bottom button+button,
.dashbox_middle_bottom_flex .dashbox_middle_bottom+.dashbox_middle_bottom {
  margin-left: 15px;
}

/* Staking Page Css */
.staking_wrapper {
  position: relative;
  padding: 120px 0 50px;
  background: url("../src/assets/images/income_plan_bg.png") no-repeat;
  background-size: cover;
  background-position-y: -320px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.staking_wrapper h2 {
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 80px;
}
.staking_wrapper h2 span {
  font-size: 48px;
  font-weight: 700;
  color: #10cffc;
  display: block;
}
.staking_box {
  background: #0a0a0a;
  border: 1px solid #f6dd7f;
  border-radius: 10px;
  padding: 30px;
  height: 100%;
}
.staking_box_row [class*="col-"] {
  margin-bottom: 30px;
}
.staking_box_top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  border-bottom: 1px solid #232323;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.staking_box_top > div {
  width: 70%;
}
.staking_box_top .primary_inp {
  border: 1px solid #242424;
  background: #0a0a0a;
  font-size: 18px;
  color: #fff !important;
}
.staking_box_top .primary_inp::placeholder {
  color: #fff;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.stake_deposit_note {
  font-size: 18px;
  color: #ff251b;
  margin: 10px 0 0;
}
.staking_box h3 {
  font-size: 30px;
  font-weight: 700;
}
.bal_label {
  font-size: 18px;
  color: #10cffc;
  margin: 0;
}
.bal_dollar {
  font-size: 22px;
  color: #10cffc;
  margin: 0;
}
.staking_box_top h4 {
  font-size: 26px;
  font-weight: 700;
  margin: 0;
}
.staking_box_bottom h3 {
  color: #cbcbcb;
  font-size: 18px;
  font-weight: 600;
}
.staking_box_bottom p {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}
.staking_box_bottom.staking_box_bottom_flex p {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}
.staking_box_bottom_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row.stake_info_box {
  margin: 15px 0 0px;
}
.stake_info_box .box{
  text-align: center;
  padding: 30px 15px;
  border: 1px solid #f6dd7f;
  border-radius: 10px;
  background: #0a0a0a;
  /* background: url("./assets/images/boxbg1.png") no-repeat center #080b20; */
  background-size: cover;
  margin-bottom: 15px;
}
.stake_info_box .box.blue{
  /* background: url("./assets/images/boxbg2.png") no-repeat center #080b20; */
  background-size: cover;
}
.stake_info_box .box.violet{
  /* background: url("./assets/images/boxbg3.png") no-repeat center #080b20; */
  background-size: cover;
}
.stake_info_box .box h5{
font-size: 24px;
color: #10cffc;
font-weight: 700;
margin-bottom: 30px;
}
.stake_info_box .box h6{
  font-size: 26px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 0;
}
.deposit_tables {
  background: url("./assets/images/recent_act_bg.png") no-repeat center;
  background-size:100% 120% ;
  padding: 70px 0 70px;
}
.deposit_tables h5{
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 15px;
}
.deposit_tables .plan_income_table th:first-child, 
.deposit_tables .plan_income_table td:first-child{
  text-align: left;
}
.deposit_tables .plan_income_table th:last-child,
.deposit_tables  .plan_income_table td:last-child{
  border-right:1px solid #232323 !important ;
}
/* Media Queries */
@media screen and (max-width: 1399px) {
  .navbar_right .butn{
    padding: 10px 25px;
  }
  .primary_box.features_box::before {
    right: -50px;
  }
  .flx_align {
    flex-direction: column;
  }
  .flx_align .dashbox_middle_bottom.left {
    text-align: right;
  }
  .mrg_adj {
    margin-top: 30px;
  }
}
@media screen and (max-width: 1199px) {
  .dashbox_middle_top h2 {
    font-size: 32px;
  }
  .plan_wrapper {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .dash_icon {
    width: 90px;
    height: 90px;
  }

  .slider .dashbox_middle_bottom h5 {
    margin-bottom: 10px !important;
  }
  .dashbox_middle_top > div {
    flex-wrap: wrap;
  }
  /* .slider .slick-prev{
    left:-10px!important;
  }
  .slider .slick-next{
    right:-10px!important;
  } */
  .slider .slick-prev,
  .slider .slick-next {
    top: -30px !important;
  }
  .primary_box.features_box::before {
    right: -44px;
    width: 12px;
    height: 12px;
  }
  .how_works_section .row {
    margin-top: 40px;
  }
  .how_works_section .box {
    margin: 15px 0;
  }
  .primary_box.dash_box.spacepad .butn {
    font-size: 16px;
  }
  .navbar_right {
    margin: 20px auto 10px;
    justify-content: center;
    flex-direction: column;
  }
  .navbar_right .dropdown {
    margin-right: 0 !important;
    margin-bottom: 10px !important;
  }
  .navbar_right .joinnowbtn {
    margin-right: 0 !important;
    margin-bottom: 10px !important;
  }
  .navbar_right.ms-3 {
    margin-left: 0 !important ;
  }
  .navbar_right.whitepaper {
    margin-bottom: -7px !important;
  }
  .navbar_right .butn.report {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .primary_box.features_box {
    max-width: 90%;
  }
  .navbar_right a+a {
    margin-left: 0 !important;
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 991px) {
  .flx_align .dashbox_middle_bottom.left {
    text-align: left;
  }
  .mrg_adj {
    margin-top: unset;
  }
  .wallet_div {
    display: block;
  }
  .inner_title {
    margin-bottom: 15px;
  }
  .wallet_div .inp_grp {
    justify-content: center;
  }
  .wallet_div label {
    margin: 0 !important;
  }
  .footer_panel {
    display: block;
    text-align: center;
  }
  .footer_social_links {
    justify-content: center;
    margin-top: 15px;
  }
  .page_header {
    padding: 240px 0 50px;
    height: 90vh;
  }
  .page_header h1 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
  }
  .page_header h5 {
    max-width: 100%;    
    text-align: center;
  }
  .d_m_none {
    display: none !important;
  }
  .d_d_none {
    display: block !important;
  }
  .dashbox_middle_top {
    flex-wrap: wrap;
  }
  .dashbox_middle_top > div {
    margin-bottom: 15px;
  }
  .buy_count h2,
  .slider .dashbox_middle_bottom h2 {
    font-size: 28px !important;
    line-height: 32px;
  }
  .buy_count h3 {
    font-size: 20px;
  }

  .features_section .features_box {
    margin: 15px 0;
    max-width: 100%;
    padding: 25px !important;
    border-radius: 30px !important;
  }
  .features_section .col-lg-4:nth-child(1) .features_box,
  .features_section .col-lg-4:nth-child(3) .features_box {
    flex-direction: column-reverse;
  }
  .features_section h4 {
    margin-top: 20px;
  }
  .above_info_box .above_box_flx div {
    margin: 0 17px;
  }
  .slider .dashbox_middle_bottom .butn {
    margin-top: 15px;
    font-size: 16px;
  }
  .navbar_right .butn {
    padding: 9px 19px;
  }
  .primary_box.features_box::before {
    display: none;
  }
  .how_works_section .box.rightbox {
    text-align: left;
  }
  .how_works_section img {
    margin-bottom: 20px;
  }
  .how_works_section .box {
    margin: 10px 0 20px;
  }
  .footer_panel_right {
    margin-top: 15px;
  }
  .above_info_box .above_box_flx div p {
    width: 115px;
  }
  .primary_box.features_box .imgbox {
    left: 0 !important;
    right: 0 !important;
  }
  .features_sectionbg .row [class*="col-"] {
    margin-top: 100px !important;
  }
  .features_sectionbg {
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  #box5 {
    overflow: auto;
  }
  .dashbox_middle_top {
    display: block;
  }
  .dashbox_middle_top > div {
    margin-bottom: 30px;
  }
  .dashbox_middle_top > div:last-child {
    margin-bottom: 0;
  }
  .dashbox_middle_top h2 {
    margin-bottom: 0;
  }
  .search_div {
    width: 100%;
  }
  .footer_panel_left a {
    font-size: 20px;
  }
  button.logout_btn {
    margin-bottom: 15px;
  }
  /* .main_navbar {
    background-color: #000e32 !important;
    box-shadow: 0 0 10px 5px rgb(4 4 4 / 50%);
  } */
  .page_header h5 {
    max-width: 100%;
  }
  .main_title {
    font-size: 36px;
  }
  .faq_section .text-right {
    text-align: left !important;
  }
  .how_register_div h2 {
    font-size: 28px;
  }
  .how_register_div_content h4 {
    font-size: 20px;
  }
  .cta_section_02 {
    background-size: cover;
  }
  .plan_wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .last_login_details {
    margin: 15px 0 0;
  }
  .inner_title {
    font-size: 36px;
  }
  .title_flex {
    flex-direction: column;
    justify-content: center;
  }
  .slider .dashbox_middle_bottom h2 {
    margin-bottom: 15px !important;
  }
  .slider .box {
    padding: 20px !important;
  }
  .platform_activity .activity_box {
    padding: 17px;
  }
  .platform_activity p {
    font-size: 17px;
  }
  .platform_activity .flxbox {
    flex-wrap: wrap;
  }
  .platform_activity .flxbox div {
    margin: 5px;
    margin-right: 15px;
    margin-left: 0;
  }
  .platform_activity .activity_box_flx {
    flex-direction: column;
    align-items: baseline;
  }
  .platform_activity .rightbtn p {
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-top: 17px;
  }
  .plan_tab.nav-pills li button.nav-link.active,
  .plan_tab.nav-pills li button.nav-link:hover {
    height: unset !important;
  }
  .head {
    max-width: 100%;
  }
  .h2tag {
    font-size: 28px;
  }
  .page_header {
    padding: 480px 0 50px;
  }
  .dashbox_middle_bottom_flex {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 575px) {
  .navbar_right .butn {
    text-align: center;
    justify-self: center;
    align-items: center;
    display: block !important;
  }
  .navbar_right .butn ,
  .navbar_right .dropdown,
  .main_navbar .navbar-collapse{
    width: 100%;
  }
  ul.walletdrop{
    width: 98%;
    left: 3px;
  }
  .staking_wrapper h2 ,.staking_wrapper h2 span{
    font-size: 32px;
  }
  .staking_box_top,.staking_box_bottom_flex{
    flex-direction: column;
  }
  .staking_box_bottom_flex >div:nth-child(1){
    margin-bottom: 15px;
  }
  .dashbox_middle_bottom h2 {
    font-size: 18px;
  }
  .inner_para {
    font-size: 18px;
  }
  .main_title_small {
    font-size: 20px;
  }
  .main_title {
    font-size: 28px;
  }
  .main_navbar .container-fluid {
    display: block;
    text-align: center;
  }
  .primary_btn {
    padding: 12px 25px;
    font-size: 16px;
  }
  .plan_wrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .wallet_div input.primary_inp {
    width: auto;
    text-overflow: ellipsis;
  }
  .dash_icon {
    width: 70px;
    height: 70px;
    margin: 0 auto 15px;
  }
  .dashbox_middle_top > div {
    flex-direction: column;
    text-align: center;
  }
  .dashbox_middle_top h2 {
    text-align: center;
    font-size: 28px;
  }
  .dash_icon img {
    width: 32px;
  }
  .dasbox {
    padding: 15px !important;
  }
  #faq_accodian .accordion-body p,
  #faq_accodian .accordion-header button {
    font-size: 16px;
  }
  .plan_income_table td h4 {
    font-size: 22px;
  }

  span.timer_label {
    font-size: 16px;
  }
  .timer_panel > span {
    min-width: 0px;
  }
  span.timer_time {
    font-size: 26px;
  }
  .dashboard_panel.join_now_panel {
    padding: 15px;
  }
  .slider .your_income {
    margin-bottom: 50px;
  }
  .slider .slick-next {
    right: 0;
    left: 46px;
    margin: 0 auto;
  }
  .slider .slick-prev {
    right: 40px !important;
    left: 0 !important;
    margin: 0 auto;
  }
  .dashbox_middle_bottom_flex {
    display: block;
  }
  .dashbox_middle_bottom button+button, .dashbox_middle_bottom_flex .dashbox_middle_bottom+.dashbox_middle_bottom {
    margin-left: 0;
  }
  button.butn.Upgrade_btn,
  .dashbox_middle_bottom.left button.butn.withdraw {
    width: 100%;
  }
}

.primary_btn {
  background: #feba31;
}
.wallet_div label {
  font-size: 15px;
}
.wallet_div input.primary_inp {
  color: #ffffff !important;
  width: 260px;
  font-size: 15px;
}
.title_flex .primary_btn {
  box-shadow: none !important;
  border: 2px solid transparent !important;
  background: linear-gradient(90deg, rgba(242,230,190,1) 0%, rgba(234,193,84,1) 57%, rgba(255,234,148,1) 100%);
  margin-left: 10px;
  min-width: 30px;
}
.title_flex .primary_btn img {
  filter: brightness(0.9) invert(1);
}
button.round_btn {
  width: 30px;
  height: 31px;
  background: transparent;
}
.dashboard_panel {
  background: #000000;
  border: 2px solid #242424;
}

.dashbox_middle_top {
  display: block;
  padding: 0;
  position: relative;
}
.dashbox_middle_top > div {
  justify-content: space-between;
}
.dashbox_middle_top h2 {
  font-size: 30px;
  margin-bottom: 0;
}
.dashbox_middle_top h4 {
  font-size: 16px;
  color: #10beda;
  padding-right: 13px;
}

.primary_box {
  background: linear-gradient(176deg, #7139d8, #1f054d) padding-box,
    linear-gradient(356deg, #180142, #813cff) border-box;
  background: linear-gradient(176deg, #431c8b, #1f054d) padding-box,
    linear-gradient(356deg, #180142, #642bcc) border-box;
  border: 2px solid transparent;
  border-radius: 20px;
}
.buy_count h2 {
  font-size: 30px;
  color: #fff;
}
.buy_count h3 {
  font-size: 20px;
  color: #c1c1c1;
  font-weight: 400;
}
.buy_count p {
  font-size: 16px;
  color: #222;
  font-weight: 600;
  text-align: center;
  border: 2px solid #e6ce77 !important;
  background: linear-gradient(90deg, rgb(238 216 151) 0%, rgb(234 193 85) 100%);
  padding: 10px;
  border-radius: 50% !important;
  height: 45px;
  width: 45px;
}
.buy_item_inner {
  position: relative;
}
.buy_item_inner .butn {
  font-size: 12px;
  font-weight: 600;
  padding: 5px 15px;
}
.buy_count {
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
}
.buy_item {
  background: transparent;
  border: 2px solid #242424;
  margin: 0px 0 30px;
  position: relative;
  padding: 15px 10px 20px;
  background: #0a0a0a;
  border: 1px solid #f6dd7f;
  box-shadow: inset 0 0 50px 0 rgba(239, 212, 93, 0.2);
}
.buy_item.yellow {
  background: linear-gradient(192deg, #ff7800, #290668) padding-box,
    linear-gradient(270deg, #22055412, #813cff) border-box;
  opacity: 1;
}
.buy_item.yellow .buy_count p {
  background: linear-gradient(195deg, #c05528, #40125d) padding-box,
    linear-gradient(360deg, #340b68, #ffc423) border-box;
  color: #fff;
}
.buy_item.blue {
  background: linear-gradient(192deg, #ffffff, #290668) padding-box,
    linear-gradient(270deg, #22055412, #813cff) border-box;
  opacity: 1;
}
.buy_item.blue .buy_count p {
  background: linear-gradient(195deg, #12446e, #06c3af) padding-box,
    linear-gradient(360deg, #07e0c700, #4edcfc) border-box;
  color: #fff;
}
.buy_item.yellow h4,
.buy_item.yellow h3,
.buy_item.blue h4,
.buy_item.blue h3 {
  color: #e0ceff;
}
.buy_item h4 {
  color: #10beda;
  margin-bottom: 4px;
}
/* .buy_item::before{
  content: "";
  position: absolute;
  background: url("../src/assets/images/buyellipse.png") no-repeat;
  width: 108px;
  height: 106px;
  top: 0;
  right: 0;
  border-radius: 19px;
} */
.buy_item .primary_btn {
  border: 0;
  box-shadow: none;
  margin-top: 25px;
  margin-bottom: -35px;
}
.tree .dashbox_middle_bottom {
  padding: 20px;
}

.plan_income_table tbody tr:nth-child(odd) {
  background-color: #0a0a0a;
}
.plan_income_table tbody tr:nth-child(even) {
  background-color: #0a0a0a;
}
.plan_income_table {
  border: 0;
  outline: 0;
}

.footer {
  background-color: #050505;
}
/*.footer_social_links li a {
  background: #000000;
  border: 0px solid #152446;
}*/

.tree {
  padding: 0 !important;
}
.tree .wholebox .boxctn h5 {
  padding: 20px 30px;
  background: linear-gradient(176deg, #0d1435, #0a0f29) padding-box, linear-gradient(358deg, #213180 40%, #213180 52%) border-box;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
}
.tree .boxctn h5.collapsed {
  background-image: url("../src/assets/images/rightarw.png");
  background-repeat: no-repeat;
  background-position: 96% center;
}
.tree .accordion .boxctn h5:not(.collapsed) {
  background-image: url("../src/assets/images/downarw.png");
  background-repeat: no-repeat;
  background-position: 96% center;
}

.dashboard_top_wrap .slider [class*="col-"] {
  margin-bottom: 0;
}
.slider .dashbox_middle_bottom h5 {
  font-size: 16px;
  color: #10beda;
  font-weight: 400;
  margin-bottom: 0;
}
.slider .dashbox_middle_bottom h2 {
  font-size: 30px;
  color: #fff;
  word-break: break-word;
}
.slider .dashbox_middle_bottom .primary_btn {
  width: unset;
  margin: 0;
  padding: 10px 30px;
}
.slider .dashbox_middle_bottom span {
  color: #ffffff;
}

.slider .box {
  background-color: #0a0a0a;
  padding: 30px;
  border-radius: 20px;
  margin: 35px 20px;
  background: linear-gradient(176deg, #0a0a0a, #0a0a0a) padding-box,
    linear-gradient(2deg, #0a0a0a, #0a0a0a) border-box;
  border: 1px solid #232323;
  /* box-shadow: inset 0px 4px 4px 1px rgb(15 211 255 / 50%); */
}

.slider .slick-prev::before,
.slider .slick-next::before {
  display: none;
}
.slider.slick-prev:before,
.slider.slick-next:before {
  display: none;
}
.slider.slick-prev {
  width: 22px;
  height: 18px;
  background: url("../src/assets/images/slideleftarw.png") !important;
}
.slider.slick-next {
  width: 22px;
  height: 18px;
  background: url("../src/assets/images/sliderightarw.png") !important;
}

.slider .slick-slide {
  outline: none;
}

.slider .slick-next:before,
.slider .slick-prev:before {
  display: none;
}

.slider .slick-prev {
  width: 22px;
  height: 18px;
  background: url("../src/assets/images/slideleftarw.png") no-repeat center !important;
  z-index: 1;
  left: unset;
  top: -20px;
  bottom: 0;
  padding: 20px;
  border: 2px solid transparent;
  right: 60px;
}

.slider .slick-prev:hover,
.slider .slick-prev:focus {
  background: url("../src/assets/images/slideleftarw.png") no-repeat center !important;
  width: 22px;
  height: 18px;
  /* border: 2px solid #5623b3;
    box-shadow: 0 0 5px 2px rgb(45 14 101);
    background-color: #8a49ff !important; */
}

.slider .slick-next {
  width: 22px;
  height: 18px;
  background: url("../src/assets/images/sliderightarw.png") no-repeat center !important;
  right: 9px;
  top: -20px;
  bottom: 0;
  border-radius: 50%;
  padding: 20px;
}

.slider .slick-next:hover,
.slider .slick-next:focus {
  background: url("../src/assets/images/sliderightarw.png") no-repeat center !important;
  width: 22px;
  height: 18px;
  /* border: 2px solid #5623b3;
  box-shadow: 0 0 5px 2px rgb(45 14 101);
  background-color: #8a49ff !important; */
}
.slider .slidebox {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0c0c0c;
  border-radius: 5px;
  padding: 15px;
  margin: 20px 20px 0;
  opacity: 0.4;
  box-shadow: 0 0 7px 2px rgb(21 19 19 / 51%);
  background: linear-gradient(176deg, #4c1f9e, #1f054d) padding-box,
    linear-gradient(2deg, #7f39ff, #7f39ff) border-box;
  border: 2px solid transparent;
}
.slider .slick-center .slidebox {
  transform: scale(1);
  position: relative;
  border: 2px solid #8a4df9;
  border-radius: 5px;
  margin-top: 0;
  padding: 30px;
  opacity: 1;
  box-shadow: none;
}

.slider .slidebox h5 {
  margin-bottom: 10px;
}
.slider .slidebox img {
  margin-bottom: 10px;
}

.dashboard_panel {  
  border: 1px solid #232323;
  background: linear-gradient(180deg, #0a0a0a 17%, #0a0a0a 26%) padding-box,
    linear-gradient(360deg, #0a0a0a 70%, #0a0a0a 100%) border-box;
  padding: 2px;
  border-radius: 19px;
}
.dasbox {
  border-radius: 20px;
  padding: 30px;
  background: #00000000;
}

.dashboard_panel.join_now_panel .title_flex {
  display: block;
}
.join_now_panel .butn {
  display: block;
  font-size: 18px;
  margin: 30px auto 15px;
}

.faq_section {
  padding-bottom: 0;
}
#faq_accodian .accordion-body p {
  color: #ffffff;
}
#faq_accodian .accordion-button.collapsed::after {
  background-image: url("./assets/images/faqright_arw.png");
  width: 9px;
  height: 15px;
  background-size: contain;
}
#faq_accodian .accordion-button::after {
  background-image: url("./assets/images/faqdown_arw.png");
  transform: none;
  width: 14px;
  height: 8px;
  background-size: contain;
}
.income_section {
  position: relative;
}
.income_section::before {
  content: "";
  background: url("./assets/images/income_plan_bg.png") no-repeat center bottom;
  width: 100%;
  height: 1159px;
  position: absolute;
}
.income_section .contract span {
  word-break: break-all;
}
.plan_tab.nav-pills {
  border: 1px solid #474747;
  background-color: #0a0a0a;
  border-radius: 10px;
  margin: 0 auto !important;
  width: fit-content;
}
.plan_tab.nav-pills li button.nav-link {
  border: 1px solid transparent;
  background-color: transparent;
  padding: 10px 30px;
  font-size: 18px;
  color: #fff;
  font-weight: 400;
}
.plan_tab.nav-pills li button.nav-link.active,
.plan_tab.nav-pills li button.nav-link:hover {
  color: #ffffff;
  background: #0a0a0a;
  border: 1px solid #10beda !important;  
  box-shadow: inset 0 0 20px 8px rgba(15, 211, 255, 0.35);
}
.register_section {
  background: #000000;
}
.how_register_div {
  border: 2px solid #242424;
}

.how_register_div::before {
  width: 66px;
  height: 46px;
}
.round_btn.primary_btn::before {
  background: transparent;
}
.primary_box.features_box::before {
  z-index: -1;
}
.primary_btn .wallet_icon {
  filter: none;
}
